<template>
  <!-- eslint-disable -->
  <div>
    <add-edit :is-add.sync="isAdd" ref="coDuenoAdd"> </add-edit>
    <b-card no-body>
      <b-card-header class="px-2 py-2 pb-0">
        <b-row class="w-100">
          <b-col lg="4" class="mb-lg-0 mb-1">
            <b-form-group label="Proyecto" label-for="project" class="">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="proyectos"
                label="code"
                input-id="project"
                :reduce="(proyectos) => proyectos.id"
                placeholder="Proyecto"
                v-model="project_id"
                class="select-obra"
                @input="getArea(project_id)"                
              >
                <template v-slot:selected-option="option">
                  {{ option.code }} - {{ option.description }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.code }} - {{ option.description }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>        
          <b-col lg="4" class="mb-lg-0 mb-1">
            <b-form-group label="Tipo" label-for="tipo">
              <v-select
                   :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tipos"
                    label="name"
                    input-id="typeCoOwner"
                    :reduce="(tipos) => tipos.code"
                    placeholder="Tipo"
                    v-model="typeCoOwner"
                    @input="filter"
                    class="select-obra"
              >               
              </v-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" class="mb-lg-0 mb-1">
            <b-form-group label="Correo" label-for="correo">
              <b-form-input @input="filter" v-model="correo" id="correo" placeholder="Correo" />
            </b-form-group>
          </b-col>
          <b-col lg="4" class="mb-lg-0 mb-1">
            <b-form-group label="Nombre" label-for="name">
              <b-form-input @input="filter" v-model="name" id="name" placeholder="Nombre" />
            </b-form-group>
          </b-col>      
          <b-col
            lg="2"
            class="mb-lg-0 mb-2 d-flex justify-content-center justify-content-lg-between align-items-center"
          >
            <div class="d-flex">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                class="mr-1"
                @click.prevent="clean"
              >
                <span class="align-middle">Ver Todos</span>
              </b-button>
            </div>
            <!-- <b-button variant="primary" class="" @click="addCoDueno">
              <span class="text-nowrap"
                ><feather-icon icon="PlusCircleIcon" size="15" /> Agregar</span
              >
            </b-button> -->
          </b-col>
        </b-row>     
      </b-card-header>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable*/
import Vue from 'vue'
import { BootstrapVue, VBToggle } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import addEdit from './add-edit.vue'
import AreasService from '@/services/AreasService'
import ProjectsService from '@/services/ProjectsService'
Vue.use(BootstrapVue)
export default {
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  data() {
    return {
      isAdd: false,
      isUpdate: false,
      see_more: false,
      area: '',
      correo: '',
      name: '',
      typeCoOwner:'004',
      project_id: JSON.parse(localStorage.getItem('project_id')),
      proyectos: [],
      records: [],
      showEntrie: 10,
      arrayFilters: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      user_role: JSON.parse(localStorage.getItem('userData')).role_user[0].role_restriction,
      tipos: [
        /* {
          code: "001",
          name: "Proyecto"
        }, */
        {
          code: "002",
          name: "Tercero (SC / Proveedores)"
        },
        {
          code: "003",
          name: "Cliente/Supervisión"
        },
        {
          code: "004",
          name: "Oficina Principal"
        }     
      ],
    }
  },
  components: {
    vSelect,
    addEdit
  },
  mounted() {  
    this.getSelect()
    this.filter()
  },
  methods: {
    clickSeeMore() {
      this.see_more = !this.see_more
    },
    addCoDueno() {
      this.isAdd = true
      this.$refs.coDuenoAdd.type_filter = this.typeCoOwner
      this.$refs.coDuenoAdd.setData({})
    },
    async getSelect() {
      const url = `?limit=100&`
      const respProyectos = await ProjectsService.getProyectos(url, this.$store)
      
      if (respProyectos.status) {
        this.proyectos = respProyectos.data.rows      
      }
    
    },   
    async filter() {
      var arrayFilter = []   
      arrayFilter.push({
          keyContains: 'area.project_id',
          key: 'equals',
          value: this.project_id
        })
      if (this.correo != '' && this.correo != null) {
        arrayFilter.push({
          keyContains: 'email',
          key: 'contains',
          value: this.correo
        })
      }
      if (this.name != '' && this.name != null) {
        arrayFilter.push({
          keyContains: 'name',
          key: 'contains',
          value: this.name
        })
      }  
      if (this.typeCoOwner !== '' && this.typeCoOwner !== null) {
        arrayFilter.push({
          keyContains: 'area.type',
          key: 'equals',
          value: this.typeCoOwner
        })
      }
    
      this.$parent.$parent.arrayFilters = arrayFilter
      this.$parent.$parent.getData()
     
      // await new Promise((resolve) => setTimeout(resolve, 100));
    },
    clean() {     
    
      var arrayFilter = []
      if (this.user_role != 'admin') {
        const area = []
        if (this.records.length > 0) {
          for (let index = 0; index < this.records.length; index++) {
            const element = this.records[index]
            area.push(element.id)
          }
          if (area.length > 0) {
            arrayFilter.push({
              keyContains: 'area_id',
              key: 'in',
              value: JSON.stringify(area)
            })
          }
        } else {
          arrayFilter.push({
            keyContains: 'area_id',
            key: 'equals',
            value: null
          })
        }
      }
      arrayFilter.push({
        keyContains: 'area.project_id',
        key: 'equals',
        value: this.project_id
      })
      this.$parent.$parent.arrayFilters = arrayFilter
      this.$parent.$parent.getData()
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.body_card {
  display: block;
  transition: 0.3s;
}
</style>
