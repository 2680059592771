/* eslint-disable*/
import VueStoreService from './VueStoreService';
const CoOwnerService = {
    async getCoOwners(filter,store){
        var resp = await VueStoreService.get("co-owner"+filter, store)
        return resp;
    },
    async saveCoOwner(data,store){
        var resp = await VueStoreService.post("co-owner",data,store)
        return resp;
    },
    async getCoOwnerById(coowner_id,store){
        var resp = await VueStoreService.get( "co-owner/"+coowner_id,store)
        return resp;
    },
    async updateCoOowner(coowner_id,data,store){
        var resp = await VueStoreService.put("co-owner/"+coowner_id,data,store)
        return resp;
    },

    async deleteCoOwner(coowner_id,store){
        var resp = await VueStoreService.delete("co-owner/"+coowner_id,store)
        return resp;
    },
}

export default CoOwnerService;